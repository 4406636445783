import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';

const ConfirmDialog = ({ open, handleClose, handleSubmit, title, text, fullWidth = false, maxWidth = 'sm', input = {}, isAlert, confirmButtomText, component, scroll="paper", clickAwayClose = false, }) => {
  const [value, setValue] = useState('');
  const handleOk = e => {
    e.preventDefault();
    setValue('');
    handleSubmit(input.title ? value : true);
  }
  useEffect(() => {
    if (input.initialValue)
      setValue(input.initialValue);
  }, [input.initialValue]);
  return (
    <Dialog
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      onClose={clickAwayClose ? handleOk : handleClose}
      scroll={scroll}
    >
      {title && <DialogTitle id="alert-dialog-title">{title}</DialogTitle>}
      <DialogContent>
        {text &&
          <DialogContentText id="alert-dialog-description">
            {text}
          </DialogContentText>
        }
        {component ? component : null}
        <form onSubmit={handleOk} id="form-dialog-form">
          {input.title &&
            <TextField
              autoFocus
              margin="dense"
              label={input.title}
              fullWidth
              type={input.type || "text"}
              name={input.name || "form-dialog-input"}
              value={value}
              onChange={e => setValue(e.target.value)}
            />
          }
        </form>
      </DialogContent>
      <DialogActions>
        <Button type="submit" form="form-dialog-form" color="primary">
          {confirmButtomText || "Ok"}
        </Button>
        {!isAlert &&
          <Button onClick={handleClose} color="primary" autoFocus={!input.title}>
            Cancel
          </Button>
        }
      </DialogActions>
    </Dialog>
  );
}

export default ConfirmDialog;