import React from "react";
import Dialog from '@material-ui/core/Dialog';

const RecordDialog = ({ hide, video }) => {
  const ratio = parseFloat(video.ratio);
  const src = `https://slidespiel.com/view/${video.alias}`;
  const height = 500;
  return (      
    <Dialog open onClose={hide} maxWidth="md">
      <iframe frameBorder="0" width={height * ratio} height={height} src={src} title={`Preview recording ${src}`} allowFullScreen />
    </Dialog>
  )
}

export default RecordDialog;
