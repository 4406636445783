import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import * as Scroll from 'react-scroll';
import { menu, individuals } from "../../data.js";

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Hidden from '@material-ui/core/Hidden';
import usePortal from "../hooks/portal";
import Filter from "../Filter";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  filter: {
    position: 'fixed',
    marginTop: 100,
    top: 0,
    [theme.breakpoints.down('sm')]: {
      position: 'static',
      margin: 0,
      marginBottom: 20,
    },
  },
  buttonContainerFixed: {
    position: 'fixed',
    //background: '-webkit-gradient(linear, left top, left bottom, from(transparent), color-stop(55%, white))',
    background: 'linear-gradient(180deg, transparent 0%, white 55%)',
    width: '100%',
    height: 50,
    bottom: 0,
    left: 0,
    paddingTop: 80,
    textAlign: 'center',
    paddingBottom: 20,
  },
  buttonContainer: {
    textAlign: 'center',
    width: '100%',
    margin: 20
  }
}));


const PortalAboutPage = props => {

  const { year, urlPrefix } = usePortal();
  const classes = useStyles();
  const [fixButton, setFixButton] = useState(false);

  const handleScroll = () => {
    const footer = document.getElementById("footer");
    if (window.innerHeight + window.pageYOffset >= (footer.offsetTop + footer.offsetHeight)) {
      setFixButton(false);
    } else {
      setFixButton(true);
    }
  }
  const handleClick = id => (e) => {
    Scroll.scroller.scrollTo(id, {
      smooth: true,
      offset: -100,
    })
  }

  const renderFilter = () => (
    <div className={classes.filter}>
      <Filter title="Activity Overview" items={menu.map(x => ({ id: x.name, title: x.text }))} selected={0} handleItemClick={handleClick} />
    </div>
  );

  useEffect(() => {
    document.addEventListener('scroll', handleScroll, false);
    return () => document.removeEventListener('scroll', handleScroll, false);
  }, [])

  const about = individuals["about" + year];
  return (
    <>
      <br />
      <br />
      <Grid container>
        <Hidden mdUp>
          <Grid item xs={12}>
            {renderFilter()}
          </Grid>
        </Hidden>
        <Grid item md={10}>
          <div>
            <Scroll.Element name="individuals">
              <Typography variant="h4">Individuals in Control of Content</Typography>
              <Typography component="div">
                <ul>
                  {individuals.list.map((item) => {
                    return <li key={item}>{item}</li>
                  })
                  }
                </ul>
              </Typography>
              <Typography variant="h6">Financial Relations for all individuals in control of content:</Typography>
              <Typography component="div">
                {individuals.relations.map((item) => {
                  return <p key={item}>{item}</p>
                })
                }
              </Typography>
              <Typography variant="h6">Disclosure of Commercial support:</Typography>
              <Typography component="div">
                {individuals.commercial.map((item) => {
                  return <p key={item}>{item}</p>
                })
                }
              </Typography>
            </Scroll.Element>

            <Scroll.Element name="about">
              <Typography variant="h4">About</Typography>
              <Typography component="div">
                {about.map((item) => {
                  return <p key={item} dangerouslySetInnerHTML={{ __html: item }} />
                })}
              </Typography>
            </Scroll.Element>
            <br />
            <Scroll.Element name="objectives">
              <Typography variant="h4" gutterBottom>Objectives</Typography>
              <Typography variant="h6">Upon completion of this conference, emergency physicians and program coordinators will:</Typography>
              <Typography component="div">
                <ol>
                  {individuals.objectives.map((item) => {
                    return <li key={item}>{item}</li>
                  })}
                </ol>
              </Typography>
            </Scroll.Element>

            <Scroll.Element name="statements">
              <Typography variant="h4">CME Statements</Typography>
              <Typography component="div">
                {individuals.statements.map((item) => {
                  return <p key={item} dangerouslySetInnerHTML={{ __html: item }} />
                })}
              </Typography>
            </Scroll.Element>

            <Scroll.Element name="contact">
              <Typography variant="h4" gutterBottom>Provider Contact Information</Typography>
              <Typography variant="h6" gutterBottom>Council of Emergency Medicine Residency Directors</Typography>
              <Typography component="div" gutterBottom>
                <address>
                  4950 W Royal Ln<br />
                  Irving, TX 75063<br />
                  888-444-2090<br />
                  <a href="mailto:cord@cordem.org">cord@cordem.org</a><br />
                  Monday – Friday 9am – 5pm (CST)<br />
                </address>
              </Typography>
              <Typography variant="h6" gutterBottom>American College of Emergency Physicians</Typography>
              <Typography component="div" gutterBottom>
                <address>
                  PO Box 619911<br />
                  Dallas, TX 75261-9911<br />
                  800-798-1822, ext 5<br />
                  <a href="mailto:membership@acep.org">membership@acep.org</a><br />
                  Monday – Friday 8am – 5pm (CST)<br />
                </address>
              </Typography>
            </Scroll.Element>

            <Scroll.Element name="method">
              <Typography variant="h4" gutterBottom>Method of Participation</Typography>
              <Typography component="div">
                <ol>
                  {individuals.method.map((item) => {
                    return <li key={item}>{item}</li>
                  })}
                </ol>
              </Typography>
            </Scroll.Element>

            <Scroll.Element name="requirements">
              <Typography variant="h4" gutterBottom>Hardware and Software Requirements</Typography>
              <Typography>
                Desktop Browsers: Google Chrome 60+, Safari 11+, Firefox 60+, IE 11+<br />
                Mobile Browsers: Safari iOS 11+, Google Chrome 60+<br />
                Bandwidth: 0.5+ Mbps
              </Typography>
            </Scroll.Element>
            <br />
            <div>
              <Typography>
                Technical support: <a href="mailto:info@slidespiel.com">info@slidespiel.com</a><br />
                CORD Privacy Policy: <a href="https://www.cordem.org/about-cord/privacy-policy/" target="_blank" rel="noopener noreferrer">https://www.cordem.org/about-cord/privacy-policy/</a><br />
                CORD Copyright Notice: <a href="https://www.cordem.org/about-cord/cord-legal-conditions/" target="_blank" rel="noopener noreferrer">https://www.cordem.org/about-cord/cord-legal-conditions/</a><br />
              </Typography>
            </div>
            <br />
            <div id="footer">
              <Typography variant="h4" gutterBottom>Disclaimer</Typography>
              <Typography>The Council of Emergency Medicine Residency Directors makes every effort to ensure that speakers of Council sponsored CME activities are knowledgeable authorities in their fields. Participants are nevertheless advised that the statements and opinions expressed by the speakers are those of the speakers, not of the Council. The speakers’ statements and opinions should not be construed as Council policy or recommendations, and the Council disclaims any liability or responsibility for the consequences of any actions taken in reliance upon those statements or opinions.
              </Typography>
            </div>

            <div className={fixButton ? classes.buttonContainerFixed : classes.buttonContainer} id="button">
              <Button to={urlPrefix + "/browse"} component={Link} variant="contained" color="primary" size="large">Accept</Button>
            </div>
          </div>
        </Grid>
        <Hidden smDown>
          <Grid item md={2}>
            {renderFilter()}
          </Grid>
        </Hidden>
      </Grid>
    </>
  );
};

export default PortalAboutPage;
