import React from 'react';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
// import GridListTileBar from '@material-ui/core/GridListTileBar';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Link } from 'react-router-dom';
import usePortal from './hooks/portal';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  image: {
    // padding: '10%',
    // width: '80%',    
    // maxHeight: 200,
  },
  bar: {
    height: 55,
  },
  tile: {
    // cursor: 'pointer',
    "& img": {
      // padding: '10%',
      // width: '80%',
      transform: 'scale(0.65) translateY(-60%)',
      transition: 'transform .5s ease',
    },
    '&:hover': {
      '& img': {
        transform: 'scale(0.75) translateY(-55%)',
      }
    }
  },
}));

const EventsGrid = ({ events }) => {
  const { urlPrefix } = usePortal();
  const classes = useStyles();
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <>
      <GridList cellHeight={250} cols={isSm ? 1 : 3}>
        {events.filter(x => moment.duration(moment() - moment(x.startDate)).asDays() < 2).map((event) => (
          // <Link to={urlPrefix + "/event/" + event.alias} key={event.id} >
            <GridListTile key={event.id} component={Link} to={urlPrefix + "/event/" + event.alias} className={classes.tile} >
              <img src={event.imageUrl} alt={event.name} className={classes.image} />
              {/* <GridListTileBar
                title={event.name}
                subtitle={moment(event.startDate).format("LL")}
                className={classes.bar}
              /> */}
            </GridListTile>
          // </Link>
        ))}
      </GridList>
    </>
  );
}

export default EventsGrid;