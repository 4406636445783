import React from "react";
//import { Link, withRouter } from "react-router-dom";
import Typography from '@material-ui/core/Typography';
import config from '../../config';
import uiConfig from '../../uiConfig';

const PortalHowToPage = () =>  {
  const { preTest, testScore } = uiConfig;
  return (
    <>
      <br />
      <br />
      <Typography variant="h6" gutterBottom>How  to  Claim  CME</Typography>
      <Typography component="div">
        <ol>
          <li>Sign In at <a href={config.url}>{config.url}</a>.</li>
          <li>Select a course and review Description and Learning Objectives.</li>
          {preTest && <li>Complete pre-test.</li>}
          <li>Complete viewing the course content.</li>
          <li>Pass post-test with {testScore}% score of higher.</li>
          <li>Click “Get my CME certificate”.</li>
        </ol>
        <p>PDF file with your certificate will be sent to you automatically on the email you used to sign in. It is also available in your “Account”.</p>
      </Typography>
    </>
  );
};

export default PortalHowToPage;
