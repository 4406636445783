import React from 'react';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import config from '../config';

const RecordingDetailsDialog = ({ show, recording, handleClose }) => {
  const { name, speakers, summary, ceHours, goals, ceType } = recording;
  const speakersString = speakers && speakers.map((speaker) => speaker.firstName + speaker.lastName).join(", ");
  return (
    <Dialog
      open={show}
      onClose={handleClose}
      aria-labelledby="details-dialog-title"
      aria-describedby="details-dialog-description"
    >
      <DialogTitle id="details-dialog-title">{name || ""}</DialogTitle>
      <DialogContent>
        <DialogContentText variant="subtitle1">
          {speakersString}
        </DialogContentText>
        {config.hasCme &&
          <DialogContentText variant="subtitle2">
            {ceHours ? `${ceHours} Hours ${ceType === 6 ? "NJSBO Oral" : "NJSOP TPA"} Credit(s)` : "No CME"}
          </DialogContentText>
        }
        <DialogContentText dangerouslySetInnerHTML={{ __html: summary }} >
        </DialogContentText>
        {goals &&
          <DialogContentText>
            <b>Learning Goals & Objectives:</b>
            <br />
            {goals}
          </DialogContentText>
        }
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary" autoFocus>
          Close
          </Button>
      </DialogActions>
    </Dialog>
  );
}

export default RecordingDetailsDialog;
