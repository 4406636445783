import React, { useEffect } from "react";
import { BrowserRouter, Switch } from "react-router-dom";
import { Provider } from "mobx-react";
import { StripeProvider } from 'react-stripe-elements';
import * as Sentry from '@sentry/browser';

import configureStore from "./store/appStore";

import Main from './components/Main';

import QuizPage from "./components/pages/QuizPage";
import PortalAboutPage from "./components/pages/PortalAboutPage";
import PreviewDialog from "./components/dialogs/PreviewDialog";

import HomePage from "./components/pages/HomePage";
import SignInDialog from "./components/dialogs/SignInDialog";
import BuyDialog from "./components/dialogs/BuyDialog";
import SignUpDialog from "./components/dialogs/SignUpDialog";
import TrackPage from "./components/pages/TrackPage";
import ChatPage from "./components/pages/ChatPage";
import PortalHowToPage from "./components/pages/PortalHowToPage";
import AccountDialog from "./components/dialogs/AccountDialog";

import uiConfig from "./uiConfig";
import { FeedbackProvider } from './components/feedback/Service';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import SecuredRoute from './components/SecuredRoute';
import Members from "./components/pages/Members";
import Users from "./components/pages/Users";
import config from './config';
import EventsRoot from "./components/EventsRoot";
import AmplitudeWrapper from "./components/AmplitudeWrapper";

const store = configureStore();

store.setIsLoggingIn(true);

if (config.sentryDsn)
  Sentry.init({
    dsn: config.sentryDsn,
  });

const palette = {
  primary: {
    main: uiConfig.mainColor,
  },
};
if (uiConfig.primaryContrastText)
  palette.primary.contrastText = uiConfig.primaryContrastText;
if (uiConfig.secondaryColor) {
  palette.secondary = { main: uiConfig.secondaryColor };
  if (uiConfig.secondaryContrastText)
    palette.secondary.contrastText = uiConfig.secondaryContrastText;
}
//console.log(palette);
const theme = createMuiTheme({ palette });

const years = config.portals.map(x => x.year).join('|');
const yearParam = `/:year(${years})?`;

const App = () => {
  useEffect(() => { document.title = config.title }, []);
  return (
    <StripeProvider apiKey={config.stripeKey}>
      <Provider store={store}>
        <AmplitudeWrapper>
          <BrowserRouter>
            <ThemeProvider theme={theme}>
              <FeedbackProvider>
                <Main>
                  <Switch>
                    {uiConfig.showAbout && <SecuredRoute exact path={yearParam + "/browse/about"} component={PortalAboutPage} />}
                    {uiConfig.showHowTo && <SecuredRoute exact path={yearParam + "/browse/how-to"} component={PortalHowToPage} />}
                    <SecuredRoute exact path={yearParam + "/quizzes/:id"} component={QuizPage} secured />
                    <SecuredRoute path={yearParam + "/members-management"} component={Members} secured />
                    <SecuredRoute path={yearParam + "/users-management"} component={Users} secured />
                    <SecuredRoute path={yearParam + "/browse/:recordId?"} component={TrackPage} secured={uiConfig.requireAuth} />
                    <SecuredRoute path={yearParam + "/event/:alias/chat"} component={ChatPage} secured />
                    <SecuredRoute path={yearParam + "/event/:id"} component={EventsRoot} />
                    <SecuredRoute path={yearParam} component={HomePage} />
                  </Switch>
                  <SecuredRoute path={yearParam + "/(.*)?/login"} component={SignInDialog} />
                  {uiConfig.showBuy && <SecuredRoute exact path={yearParam + "/(.*)?/buy"} component={BuyDialog} />}
                  {uiConfig.showSignUp && <SecuredRoute exact path={yearParam + "/(.*)?/signup"} component={SignUpDialog} />}
                  <SecuredRoute exact path="/(.*)/preview/:recordId" component={PreviewDialog} secured />
                  <SecuredRoute path={yearParam + "/(.*)?/account"} component={AccountDialog} secured />
                </Main>
              </FeedbackProvider>
            </ThemeProvider>
          </BrowserRouter>
        </AmplitudeWrapper>
      </Provider>
    </StripeProvider>
  );
};

export default App;
