import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => {
  return ({
    fullWidth: ({ height, minHeight }) => ({
      width: '100vw',
      position: 'relative',
      left: '50%',
      right: '50%',
      marginLeft: '-50vw',
      marginRight: '-50vw',
      // height,
      // minHeight,
      // backgroundSize: 'cover !important',
      // display: 'flex',
      // justifyContent: 'flex-end',
      // alignItems: 'center',
      '& iframe': {
        width: '100vw',
        border: 'none',
        overflow: 'hidden',
      }
    }),
    content: {
      whiteSpace: 'break-spaces'
    },
    header: {
      textAlign: 'center',
      marginTop: -30,
      '& img': {
        height: 90,
      }
    }
  })
});

const formatName = ({ firstName, lastName }) => (firstName ? firstName + " " : "") + (lastName || "");

const NetworkingRoom = ({ room, event, user, store }) => {
  const classes = useStyles();
  if (!user) return null;
  const iframeQuery = `?userName=${formatName(user)}&email=${user.email}`;
  return (
    <div className={classes.fullWidth}>
      <iframe src={`https://slidespiel.com/${event.alias}/networking-room/${room.id}${iframeQuery}`} allowFullScreen allow="camera; microphone" style={{ height: window.innerHeight - 90 }} scrolling="no" />
    </div>
  );
}

export default NetworkingRoom;