import React, { useState } from "react";
import PropTypes from "prop-types";
//import { useFeedback } from "../feedback/Service";
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { Link as RouterLink, useLocation } from "react-router-dom";
import Link from '@material-ui/core/Link';

const useStyles = makeStyles(theme => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginBottom: theme.spacing(1),
  },
  stripe: {
    border: '1px solid ' + theme.palette.text.secondary,
    padding: 10,
    borderRadius: 4,
    fontSize: '1rem',
  }
}));

const SignUpForm = ({ handleSubmit, setLoading }) => {
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const classes = useStyles();
  const { pathname } = useLocation();
  //const feedback = useFeedback();

  const handleFormSubmit = async e => {
    e.preventDefault();
    setLoading(true);
    handleSubmit({ firstName, lastName, email });
  }
  return (
    <form onSubmit={handleFormSubmit} id="buy-form" className={classes.form}>
      <TextField
        variant="outlined"
        autoFocus
        margin="dense"
        label="First Name"
        fullWidth
        value={firstName}
        onChange={e => setFirstName(e.target.value)}
        required
      />
      <TextField
        variant="outlined"
        margin="dense"
        label="Last Name"
        fullWidth
        value={lastName}
        onChange={e => setLastName(e.target.value)}
        required
      />
      <TextField
        variant="outlined"
        margin="dense"
        id="email"
        label="Email"
        type="email"
        fullWidth
        value={email}
        onChange={e => setEmail(e.target.value)}
        required
      />
      <br />
      {pathname.includes("/signup") &&
        <Typography gutterBottom>
          <Link to={pathname.replace("/signup", '/login')} component={RouterLink}>Already have an account? Sign In</Link>
        </Typography>
      }
    </form>
  );
}

SignUpForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  setLoading: PropTypes.func.isRequired,
};

export default SignUpForm;
