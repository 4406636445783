import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { inject } from "mobx-react";

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Link from '@material-ui/core/Link';
import CircularProgress from '@material-ui/core/CircularProgress';
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Link as RouterLink, useLocation } from "react-router-dom";
import { useAmplitude } from "react-amplitude-hooks";

import api from "../../api";
import usePortal from "../hooks/portal";
import { useFeedback } from "../feedback/Service";
import BuyRecordForm from '../forms/BuyRecordForm';
import Stepper from "../BuyStepper";
import SignUpForm from "../forms/SignUpForm";
import Pricing from "../Pricing";
import { tiers } from "../../data";
import CloseButton from "../CloseButton";

const useStyles = makeStyles(theme => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginBottom: theme.spacing(1),
  },
  submit: {
    //margin: theme.spacing(2, 0, 2),
    minWidth: 120,
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  wrapper: {
    position: 'relative',
  },
  // close: {
  //   padding: theme.spacing(0.5),
  // },
  support: {
    //marginBottom: 0,
    minWidth: 200,
    marginLeft: 15,
  },
  stripe: {
    border: '1px solid ' + theme.palette.text.secondary,
    padding: 10,
    borderRadius: 4,
    fontSize: '1rem',
  },
  price: {
    color: theme.palette.text.primary,
    margin: 0,
  },
  dialogTitle: {
    paddingBottom: 0,
  },
  dialogContent: {
    overflowY: 'visible',
  },
  listItem: {
    padding: theme.spacing(1, 0),
  },
  listItemSecondary: {
    whiteSpace: "break-spaces",
  },
  close: {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1),
  }
}));

const getTier = index => tiers[index];

const SignUpAndBuyDialog = ({ store, recording, user, handleClose, track }) => {
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(user ? (tiers.length > 1 ? 1 : 2) : 0);
  const [plan, setPlan] = useState(0);
  const showSignUpStep = useRef(!!user);
  const { logEvent } = useAmplitude({ page: "buy recording" });
  const { pathname } = useLocation();
  const steps = [
    { id: 0, label: "Sign Up" },
    { id: 1, label: "Choose Tier", caption: plan === null ? null : getTier(plan)?.summary },
    { id: 2, label: "Checkout" },
  ].filter(x => x.id !== 0 || !showSignUpStep.current).filter(x => x.id !== 1 || tiers.length > 1);

  const feedback = useFeedback();
  const { portalName, portalId } = usePortal();
  const classes = useStyles();

  const handleSignUp = async ({ firstName, lastName, email, token, code }) => {
    try {
      const { body: data } = await api.register(email, firstName, lastName, token, portalId, portalName);
      console.log(data);
      localStorage.setItem(`${portalName}-access_token`, data.accessToken);
      const { body: user } = await api.getUser("me", data.accessToken);
      feedback.snackbar({ text: "Registration completed!", type: "success" });
      store.setUser(user);
      logEvent("signup");
      setStep(tiers.length > 1 ? 1 : 2);
    }
    catch (ex) {
      console.warn("Register error", ex.response);
      feedback.snackbar({ text: ex.response.body.message, type: "error" });
    }
    setLoading(false);
  }

  const handlePriceSelect = plan => () => {
    setPlan(plan);
    setStep(2);
  }

  const handleBuy = async ({ token, code }) => {
    try {
      let data;
      if (plan === 0) {
        const { body } = await api.buyRecording(recording.id, token, portalName);
        data = body;
      }
      if (plan === 1) {
        const { body } = await api.buyTrack(track.id, token, code, portalName);
        data = body;
      }
      if (plan === 2) {
        await api.buyAll(token, code, portalName);
        data = 'all';
      }
      logEvent("buy " + getTier(plan)?.title?.toLowerCase());
      console.log(data);
      feedback.snackbar({ text: "Payment completed!", type: "success" });
      handleClose(data);
    }
    catch (ex) {
      console.warn("Payment error", ex.response);
      feedback.snackbar({ text: ex.response?.body?.message || "Payment error", type: "error" });
      setLoading(false);
    }
  }

  const handleSubmit = async data => {
    if (step == 0) {
      handleSignUp(data);
      return;
    }
    if (step == 2) {
      handleBuy(data);
      return;
    }
  }

  useEffect(() => {
    if (user && step === 0)
      setStep(tiers.length > 1 ? 1 : 2);
  }, [user]);

  useEffect(() => {
    if (recording.alias)
      handleClose();
  }, [recording.alias])

  const tier = getTier(plan);
  const price = user?.isMember ? recording.priceForMembers : recording.price;
  let title = "Buy Recording";
  if (plan === 1)
    title = "Buy Track";
  if (plan === 2)
    title = "Buy Conference Access"
  const secondary = plan !== 1 ? null : track.recordings.map(x => x.name).join("\r\n");
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Dialog open onClose={() => handleClose()} aria-labelledby="buy-dialog-title" fullWidth maxWidth={step === 1 ? "md" : "sm"} fullScreen={fullScreen}>
      {fullScreen && <CloseButton onClick={() => handleClose()} />}
      <DialogTitle id="buy-dialog-title" className={classes.dialogTitle}>{title}</DialogTitle>
      <DialogContent className={classes.dialogContent}>
        {plan !== 2 &&
          <DialogContentText>
            {plan === 1 ? track.title : recording.name}<br />
          </DialogContentText>
        }
        {steps.length > 1 && <Stepper step={step} steps={steps} />}
        {/* <DialogContentText variant="subtitle1" className={classes.price}>
          {user?.isMember ? "Member " : "Regular "}price: ${price}
        </DialogContentText> */}
        {step === 0 &&
          <>
            <SignUpForm handleSubmit={handleSubmit} setLoading={setLoading} />
            <Typography gutterBottom>
              <Link to={pathname + '/login'} component={RouterLink}>Already have an account? Sign In</Link>
            </Typography>
          </>
        }
        {step === 1 &&
          <Pricing handleSelect={handlePriceSelect} isMember={user?.isMember} hideTrackTier={track?.title === "Keynote"} />
        }
        {step === 2 &&
          <>
            <Typography variant="h6">
              Order summary
            </Typography>
            <List disablePadding>
              <ListItem className={classes.listItem}>
                <ListItemText primary={getTier(plan).summary} secondary={secondary} classes={{ secondary: classes.listItemSecondary }} />
                <Typography variant="subtitle1">${price}</Typography>
              </ListItem>
            </List>
            <BuyRecordForm handleSubmit={handleSubmit} setLoading={setLoading} showCode={!user.isMember && plan !== 0} helperText={"Discount price: $" + tier.memberPrice} />
          </>
        }
        {(step == 0 || step === 2) &&
          <div className={classes.wrapper}>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              disabled={loading}
              form="buy-form"
            >
              {step === 2 ? <>Pay ${price}</> : "Next"}
            </Button>
            {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
          </div>
        }
      </DialogContent>
      <DialogActions>
        <Typography className={classes.support} variant="body2" gutterBottom>Support: <Link href="mailto:info@slidespiel.com">info@slidespiel.com</Link></Typography>
        <div style={{ width: '100%' }} />
      </DialogActions>
    </Dialog>
  );
}

SignUpAndBuyDialog.propTypes = {
  store: PropTypes.object.isRequired
};

export default inject(({ store }, props) => ({
  user: store.currentUser, store
}))(SignUpAndBuyDialog);
