import React, { useState } from "react";
import PropTypes from "prop-types";
import { useHistory, useLocation } from "react-router-dom";
import { inject } from "mobx-react";

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useAmplitude } from "react-amplitude-hooks";

import api from "../../api";
import usePortal from "../hooks/portal";
import { useFeedback } from "../feedback/Service";
import SignUpForm from '../forms/SignUpForm';
import CloseButton from "../CloseButton";

const useStyles = makeStyles(theme => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginBottom: theme.spacing(1),
  },
  submit: {
    //margin: theme.spacing(2, 0, 2),
    minWidth: 120,
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  wrapper: {
    position: 'relative',
  },
  close: {
    padding: theme.spacing(0.5),
  },
  support: {
    //marginBottom: 0,
    minWidth: 200,
    marginLeft: 15,
  },
  stripe: {
    border: '1px solid ' + theme.palette.text.secondary,
    padding: 10,
    borderRadius: 4,
    fontSize: '1rem',
  }
}));

const SignUpDialog = ({ store }) => {

  const [loading, setLoading] = useState(false);

  const feedback = useFeedback();
  const history = useHistory();
  const { portalName, portalId, urlPrefix, uiSettings } = usePortal();
  const { logEvent } = useAmplitude({ page: "signup" });
  const classes = useStyles();
  // const redirectUrl = urlPrefix || "/";
  const { pathname } = useLocation();
  const redirectUrl = pathname === "/signup" ? urlPrefix + uiSettings.signInRedirect : pathname.replace("/signup", "");
  const backUrl = pathname === "/signup" ? urlPrefix : pathname.replace("/signup", "");

  const handleClose = () => history.push(backUrl);
  const handleSubmit = async ({ firstName, lastName, email, token }) => {
    try {
      const { body: data } = await api.register(email, firstName, lastName, token, portalId, portalName);
      console.log(data);
      localStorage.setItem(`${portalName}-access_token`, data.accessToken);
      const { body: user } = await api.getUser("me", data.accessToken);
      feedback.snackbar({ text: "Registration completed!", type: "success" });
      store.setUser(user);
      logEvent("signup")
      history.push(redirectUrl);
    }
    catch (ex) {
      console.warn("Register error", ex.response);
      feedback.snackbar({ text: ex.response.body.message, type: "error" });
      setLoading(false);
    }
  }

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Dialog open onClose={handleClose} aria-labelledby="buy-dialog-title" fullWidth fullScreen={fullScreen}>
    {fullScreen && <CloseButton onClick={handleClose} />}
      <DialogTitle id="buy-dialog-title">Sign Up</DialogTitle>
      <DialogContent>
        <SignUpForm handleSubmit={handleSubmit} setLoading={setLoading} />
        <div className={classes.wrapper}>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={loading}
            form="buy-form"
          >
            Sign Up
          </Button>
          {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
        </div>
      </DialogContent>
      <DialogActions>
        <Typography className={classes.support} variant="body2" gutterBottom>Support: <Link href="mailto:info@slidespiel.com">info@slidespiel.com</Link></Typography>
        <div style={{ width: '100%' }} />
      </DialogActions>
    </Dialog>
  );
}

SignUpDialog.propTypes = {
  store: PropTypes.object.isRequired
};

export default inject("store")(SignUpDialog);
