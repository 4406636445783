import React, { Fragment, useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import { eventsContent } from '../../data';
// import api from '../../api';
// import Schedule from '../Schedule';
// import AttentionTracker from '../AttentionTracker';
import { useAmplitude } from "react-amplitude-hooks";
import { inject } from "mobx-react";
import RestrictedAccessPage from "./RestrictedAccessPage";
import EventSignUpDialog from '../dialogs/EventSignUpDialog';
import { useHistory } from 'react-router-dom';
// import { useFeedback } from '../feedback/Service';

const useStyles = makeStyles(theme => {
  return ({
    fullWidth: ({ height, minHeight }) => ({
      width: '100vw',
      position: 'relative',
      left: '50%',
      right: '50%',
      marginLeft: '-50vw',
      marginRight: '-50vw',
      // height,
      // minHeight,
      // backgroundSize: 'cover !important',
      // display: 'flex',
      // justifyContent: 'flex-end',
      // alignItems: 'center',
      '& iframe': {
        width: '100vw',
        border: 'none',
        overflow: 'hidden',
      }
    }),
    content: {
      whiteSpace: 'break-spaces'
    },
    header: {
      textAlign: 'center',
      marginTop: -30,
      '& img': {
        height: 90,
      }
    }
  })
});

const formatName = ({ firstName, lastName }) => (firstName ? firstName + " " : "") + (lastName || "");

const EventLive = ({ event, user, store }) => {
  const classes = useStyles();
  const [tab, setTab] = useState(0);
  // const [schedule, setSchedule] = useState([]);
  const [showDialog, setShowDialog] = useState(false);
  const { logEvent } = useAmplitude({ page: event.alias, email: user?.email });
  // const feedback = useFeedback();
  const history = useHistory();

  const handleDialogClose = (success, exhibitHallOnly) => {
    if (!success) {
      history.push("/");
      return;
    }
    store.fetchEvents();
    setShowDialog(false);
    if (exhibitHallOnly) {
      history.push(`/event/${event.alias}/exhibit-hall`);
    }
    logEvent("event registration completed");
  }

  // useEffect(() => {
  //   let didCancel = false;
  //   if (!event?.id) return;
  //   api.getSchedule(event.id).then(data => {
  //     if (didCancel) return;
  //     setSchedule(data);
  //   });
  //   return () => didCancel = true;
  // }, [event]);

  useEffect(() => {
    if (event.hasAccess) return;
    if (!event.canRegister) return;
    setShowDialog(true);
  }, [event.hasAccess, event.canRegister])

  const eventContent = eventsContent.find(x => x.eventId === event.id);
  const currentTab = eventContent?.tabs[tab];
  const iframeQuery = user ? `?chatUserName=${formatName(user)}&email=${user.email}&showSchedule=1` : "";
  if (!event.hasAccess) {
    if (event.canRegister)
      return (<EventSignUpDialog open={showDialog} handleClose={handleDialogClose} user={user} event={event} store={store} />);
    else
      return (<RestrictedAccessPage />);
  }
  let { alias } = event;
  if (alias === 'intermountain-2020-09-24-day-2')
    alias = 'intermountain-2020-09-24';
  return (
    <>
      <div className={classes.fullWidth}>
        <iframe src={`https://slidespiel.com/${alias}/live/embed${iframeQuery}`} allowFullScreen style={{ height: window.innerWidth * 0.4 + 3850, minHeight: 400 }} scrolling="no" />
      </div>
      {/* <AttentionTracker user={user} logEvent={logEvent} /> */}
      {/* <img src="https://sspblobs.azureedge.net/sites/429c8490-5e79-4182-b47f-7263530f12c8/events/16204a2b-6d19-4354-ba79-65a6933f945e/eventInfo1.jpg" style={{ width: '100%' }} /> */}
      {eventContent &&
        <>
          {/* <div className={classes.header}>
            <Typography variant="h5">Academic Day</Typography>
            <Typography variant="h5" gutterBottom>Saturday, June 20, 2020</Typography>
            <Typography variant="h5">Presented by</Typography>
            <Typography variant="h5" gutterBottom>Miller School of Medicine, University of Miami</Typography>
            <Typography variant="h5">Joint Providership with</Typography>
            <img src='https://sspblobs.azureedge.net/sites/7871ac4f-f03d-43fe-b268-9312dd9c0552/events/7df172b1-a2a8-41ad-9290-6acb30610e32/mec-logo.jpg' />
            <br />
          </div> */}
          <Tabs
            value={tab}
            indicatorColor="primary"
            textColor="primary"
            onChange={(x, value) => setTab(value)}
            aria-label="disabled tabs example"
            centered
          >
            {(eventContent?.tabs || []).map((x, i) => <Tab label={x.name} key={i} />)}
          </Tabs>
          <div className={classes.content} style={currentTab.textAlign ? { textAlign: currentTab.textAlign } : {}}>
            <br />
            {currentTab.description && <><Typography>{currentTab.description}</Typography><br /></>}
            {(currentTab.items || []).map((item, index) => (
              <Fragment key={index}>
                <Typography variant="h6" gutterBottom>{item.title}</Typography>
                {item.image && <img src={item.image} style={{ marginBottom: 10 }} />}
                {item.text && <Typography>{item.text}</Typography>}
                {item.list &&
                  <Typography component="div">
                    <ol>
                      {item.list.map(x => <li key={x}>{x}</li>)}
                    </ol>
                  </Typography>
                }
                {item.texts && item.texts.map(textItem => (
                  <Fragment key={textItem.text}>
                    {textItem.subtitle && <Typography><strong>{textItem.subtitle}</strong></Typography>}
                    {textItem.text && <Typography>{textItem.text}</Typography>}
                    <br />
                  </Fragment>
                ))
                }
                <br />
              </Fragment>
            ))}
            {/* {tab === 3 && <Schedule data={schedule.filter(x => !x.roomName.toLowerCase().includes("test"))} />} */}
          </div>
        </>
      }
    </>
  );
}

export default inject("store")(EventLive);

