import React, { useState } from "react";
import PropTypes from "prop-types";
import { useFeedback } from "../feedback/Service";
import { CardElement, injectStripe, Elements } from 'react-stripe-elements';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles(theme => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginBottom: theme.spacing(1),
  },
  stripe: {
    border: '1px solid ' + theme.palette.text.secondary,
    padding: 10,
    borderRadius: 4,
    fontSize: '1rem',
  }
}));

const BuyForm = ({ handleSubmit, stripe, setLoading }) => {
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const classes = useStyles();
  const feedback = useFeedback();

  const getToken = async e => {
    e.preventDefault();
    setLoading(true);
    const { token, error } = await stripe.createToken({ type: 'card' });
    if (error){
      console.warn("Payment error", error);
      feedback.snackbar({ text: error.message, type: "error" });
      setLoading(false);
      return;
    }
    if (!token?.id){
      console.warn("Empty token", token);
      feedback.snackbar({ text: "Unknown payment error", type: "error" });
      setLoading(false);
      return;
    }
    handleSubmit({ firstName, lastName, email, token: token.id });
  }
  return (
    <form onSubmit={getToken} id="buy-form" className={classes.form}>
      <TextField
        variant="outlined"
        autoFocus
        margin="dense"
        label="First Name"
        fullWidth
        value={firstName}
        onChange={e => setFirstName(e.target.value)}
        required
      />
      <TextField
        variant="outlined"
        margin="dense"
        label="Last Name"
        fullWidth
        value={lastName}
        onChange={e => setLastName(e.target.value)}
        required
      />
      <TextField
        variant="outlined"
        margin="dense"
        id="email"
        label="Email"
        type="email"
        fullWidth
        value={email}
        onChange={e => setEmail(e.target.value)}
        required
      />
      <br />
      <br />
      <CardElement style={{ base: { fontSize: 14 } }} className={classes.stripe} hidePostalCode />
    </form>
  );
}

BuyForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  setLoading: PropTypes.func.isRequired,
  stripe: PropTypes.object.isRequired,
};

const StripeInjectedFrom = injectStripe(BuyForm);

const StripeForm = props => (
  <Elements>
    <StripeInjectedFrom {...props} />
  </Elements>
)

export default StripeForm