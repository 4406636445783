import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useHistory, /* useLocation */ } from "react-router-dom";
import { inject } from "mobx-react";
import config from "../../config";

import api from "../../api";

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import List from '@material-ui/core/List';
import Link from '@material-ui/core/Link';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
//import ListSubheader from '@material-ui/core/ListSubheader';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import DownloadIcon from '@material-ui/icons/SaveAlt';
import { makeStyles } from '@material-ui/core/styles';
import usePortal from "../hooks/portal";

const useStyles = makeStyles(theme => ({
  support: {
    marginBottom: 0,
    minWidth: 200,
    marginLeft: 15,
  },
}));

const AccountDialog = props => {
  const [isFullyLoaded, setIsFullyLoaded] = useState(true);
  const [certificates, setCertificates] = useState(null);
  const [isDownloading, setIsDownloading] = useState(false);
  const history = useHistory();
  const classes = useStyles();
  //const location = useLocation();
  const { portalName } = usePortal();

  const handleClose = () => {
    history.goBack();
  }

  const handleGetCertificateClick = (recordingId, name) => () => {
    setIsDownloading(true);
    api.getCertificate(recordingId).then((blob) => {
      const url = URL.createObjectURL(blob.body),
        linkEl = document.createElement("a");
      setIsDownloading(false);
      linkEl.href = url;
      linkEl.download = name;
      linkEl.click();

      setTimeout(() => {
        URL.revokeObjectURL(url);
      }, 100, url);
    });
  }

  useEffect(() => {
    if (!config.hasCme) return;
    setIsFullyLoaded(false);
    api.getCertificates(portalName).then((data) => {
      setCertificates(data.body);
      setIsFullyLoaded(true);
    });
  }, [portalName]);

  return (
    <Dialog open onClose={handleClose} aria-labelledby="account-dialog-title" fullWidth>
      <DialogTitle id="account-dialog-title">Account</DialogTitle>
      <DialogContent>
        {!props.isLoggingIn && isFullyLoaded ?
          <>
            <Typography>{props.user.firstName} {props.user.lastName}</Typography>
            <Typography gutterBottom>{props.user.email}</Typography>
            <br />
            {config.hasCme &&
              <>
                <DialogContentText>
                  Your CME certificates:<br />
                </DialogContentText>
                {certificates === null || isDownloading ?
                  <CircularProgress /> :
                  certificates.length === 0 ?
                    <Typography>No certificates yet.</Typography> :
                    <List
                      dense
                      component="nav"
                      aria-labelledby="certificates-list-subheader"
                      //subheader={
                      //  <ListSubheader component="div" id="certificates-list-subheader">
                      //    Your CME certificates
                      //  </ListSubheader>
                      //}
                      >
                      {certificates.map((certificate) => (
                        <ListItem button key={certificate.recordId} onClick={handleGetCertificateClick(certificate.recordId, certificate.name)}>
                          <ListItemIcon>
                            <DownloadIcon />
                          </ListItemIcon>
                          <ListItemText primary={certificate.name} />
                        </ListItem>
                      ))}
                    </List>
                }
              </>}
          </> :
          <CircularProgress />
        }      
      </DialogContent>
      <DialogActions>
        <Typography className={classes.support} variant="body2">Support: <Link href="mailto:info@slidespiel.com">info@slidespiel.com</Link></Typography>
        <div style={{ width: '100%' }} />
        <Button onClick={handleClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

AccountDialog.propTypes = {
  isLoggingIn: PropTypes.bool,
};

export default inject(({ store }, props) => ({
  isLoggingIn: store.isLoggingIn, user: store.currentUser,
}))(AccountDialog);
