import React, { useState, useEffect } from 'react';
import { makeStyles, useTheme, /* fade */ } from '@material-ui/core/styles';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import Typography from '@material-ui/core/Typography';
// import MuiLink from '@material-ui/core/Link';
import { Link } from "react-router-dom";
// import usePortal from '../hooks/portal';
import RestrictedAccessPage from "./RestrictedAccessPage";
import api from '../../api';
import { useMediaQuery } from '@material-ui/core';
import { useFeedback } from '../feedback/Service';
// import { prizeSchedule } from '../../data';

const useStyles = makeStyles(theme => ({
  headerTile: {
    // height: 'auto !important'
  },
  tile: {
    '& img': {
      transition: 'transform .4s ease',
    },
    '&:hover': {
      '& img': {
        transform: 'scale(1.05) translateY(-50%)',
      }
    }
  },
  bar: {
    // backgroundColor: fade(theme.palette.primary.main, 0.7),
    height: 50
  },
  barTitle: {
    // fontSize: '0.92rem'
  },
  prizeScheduleLink: {
    cursor: 'pointer',
    fontWeight: 'bold',
  }
}));

// const PrizeSchedule = () => (
//   <>
//     {prizeSchedule.map(drawing => (
//       <React.Fragment key={drawing.title}>
//         <Typography variant="h6">{drawing.title} {drawing.date}</Typography>
//         <ul>
//           {drawing.items.map(item => <Typography component="li" key={item}>{item}</Typography>)}
//         </ul>
//       </React.Fragment>
//     ))}
//   </>
// )

// const alertText = "By clicking agree, you will be entered into the Exhibit Hall Raffle Program and you will receive communication from exhibitors. Six separate raffle prize drawings will be held. The raffle schedule can be found on the Exhibit Hall page. Visit 10 exhibitor booths during each time period to be entered into the prize program.";

const ExhibitHall = ({ event, store }) => {
  const classes = useStyles();
  const [data, setData] = useState([]);
  const feedback = useFeedback();

  // const handleShowPrizeSchedule = () => {
  //   feedback.alert({ component: (<PrizeSchedule />), fullWidth: true, confirmButtomText: "Close", maxWidth: "md", scroll: "body", clickAwayClose: true });
  // }

  useEffect(() => {
    let didCancel = false;
    if (!event.id) return
    const getData = async () => {
      store.setIsFetching(true);
      const data = await api.getExhibitors(event.id);
      if (didCancel) return;
      console.log(data);
      setData(data);
      store.setIsFetching(false);
    }
    getData();
    return () => { didCancel = true }
  }, [event.id]);

  // useEffect(() => {
  //   if (!localStorage.getItem("exhibit-hall-alert-fired")) {
  //     feedback.alert({ text: alertText, confirmButtomText: "agree" });
  //     localStorage.setItem("exhibit-hall-alert-fired", 1);
  //   }
  // }, []);

  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down('sm'));

  if (!event.hasExhibitHallAccess) {
    return (<RestrictedAccessPage />);
  }

  return (
    <>
      <br />
      <br />
      {/* <Typography variant="h4" gutterBottom>Welcome to the Virtual Summit Exhibit Hall!</Typography>
      <Typography variant="subtitle1">Visit our Summit exhibitors by clicking on their logos below.</Typography>
      <Typography>
        NCSEA will be holding six raffle prize drawings during October and November. If you visit 10
        booths during one of the timeframes, you will be automatically entered into that drawing. The list of raffle prizes
        and the dates of each prize drawing can be found <MuiLink className={classes.prizeScheduleLink} onClick={handleShowPrizeSchedule}>here</MuiLink>.
      </Typography>
      <Typography>Raffle prize winners will be announced on the NCSEA website <MuiLink className={classes.prizeScheduleLink} href="http://www.ncsea.com/events/annualconference/tradeshow2/raffles/" target="_blank" rel="noopener noreferrer">here</MuiLink>.</Typography> */}
      {event.enableExhibitHall ?
        <GridList cellHeight={230} cols={isSm ? 2 : 5} spacing={1}>
          {data.map((item, index) => (
            <GridListTile className={classes.tile} component={Link} to={"/event/" + event.alias + "/exhibitor/" + item.id} key={item.id} >
              <img src={item.imageUrl} alt={item.title} />
              <GridListTileBar
                title={item.title}
                className={classes.bar}
                classes={{ title: classes.barTitle }}
                subtitle={<span>{item.subtitle}</span>}
              />
            </GridListTile>
          ))}
        </GridList>
        :
        <>
          <Typography variant="h4" gutterBottom>Exhibit Hall Opening Hours (Eastern Time)</Typography>
          <Typography variant="h6" gutterBottom>Saturday November 7, 2020</Typography>
          <Typography variant="subtitle1" gutterBottom>9.30 – 10.30 AM</Typography>
          <Typography variant="subtitle1" gutterBottom>1.45 – 2.15 PM</Typography>
          <Typography variant="subtitle1" gutterBottom>5.35 – 6.15 PM</Typography>
          <br />
          <Typography variant="h6" gutterBottom>Sunday November 8, 2020</Typography>
          <Typography variant="subtitle1" gutterBottom>9.30 – 10.30 AM</Typography>
          <Typography variant="subtitle1" gutterBottom>1.45 – 2.15 PM</Typography>
          <Typography variant="subtitle1" gutterBottom>5.35 – 6.15 PM</Typography>
        </>
      }
      <br />
    </>
  );
}

export default ExhibitHall;